import { render, staticRenderFns } from "./SearchResultItem.vue?vue&type=template&id=1ed25d21&scoped=true"
import script from "./SearchResultItem.vue?vue&type=script&lang=js"
export * from "./SearchResultItem.vue?vue&type=script&lang=js"
import style0 from "./SearchResultItem.vue?vue&type=style&index=0&id=1ed25d21&prod&scoped=true&lang=scss"
import style1 from "./SearchResultItem.vue?vue&type=style&index=1&id=1ed25d21&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ed25d21",
  null
  
)

export default component.exports