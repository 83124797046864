<template>
    <div class="row accommodation-item">

        <!-- Favorite icon -->
        <div class="fav">
            <img :src="require(`@/assets/corazon.svg`)" @click.prevent="favorite()" v-if="hearth === true" />
            <img :src="require(`@/assets/corazon-borde.svg`)" @click.prevent="favorite()" v-else />
        </div>

        <div class="row accommodation-item__content">

            <!-- Pictures -->
            <div class="col-sm-12 col-md-6 picture">
                <CarouselList 
                    :idAlojamiento="accommodation.idalojamiento"
                    :externalReference="accommodation.external_reference"
                    :photos="accommodation.gallery"
                />
            </div>
            
            <!-- Info -->
            <div class="col-12 col-md-6 info-data">

                <!-- Location -->
                <div>{{ location }}</div>

                <!-- Title -->
                <div class="location">{{ title | capitalFirst }}
                </div>

                <!-- Rooms number -->
                <div>{{ accommodation.room.length > 0 ? $t('room.number') + accommodation.room.length : "" }}</div>

                <!-- Equipment -->
                <div class="equipments">{{ accommodationRooms(accommodation.room) }}</div>

                <!-- Price -->
                <div class="values">

                    <!-- Daily -->
                    <template v-if="showDailyPrice">
                        <div class="daily">{{ dailyPrice | convertAndFormatPrice }} / {{ $t('accommodation.day') }}</div>
                    </template>

                    <!-- Monthly -->
                    <div v-if="showMonthlyPrice" class="monthly">
                        {{ monthlyPrice | convertAndFormatPrice }} / {{ $t('accommodation.month') }}
                    </div>

                    <!-- Show dates range -->
                    <div v-if="startDate !== undefined && endDate !== undefined" class="dates-range">
                        {{ $t('block-dates.from') }}: {{ startDate }} · {{ $t('block-dates.to') }}: {{ endDate }}
                    </div>
                    
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { IsJsonString } from "../../Utils/Utils";
import axios from "axios";
import * as moment from "moment";
import categories from "../../components/Banner/Category.json";
import priceMixin from "@/mixins/priceMixin";
import CarouselList from "@/components/Carousel/CarouselList.vue";
export default {
    components: {CarouselList},
    props: [
        "accommodation",
        "startDate",
        "endDate",
    ],
    mixins: [ priceMixin ],
    data() {
        return {
            categories,
            url: process.env.VUE_APP_S3_URL,
            slide: 0,
            sliding: null,
            hearth: false,
        };
    },
    methods: {
        isJson(str) {
            return IsJsonString(str);
        },
        onSlideStart(slide) {
            this.sliding = true;
        },
        onSlideEnd(slide) {
            this.sliding = false;
        },
        accommodationType(type) {
            let cat = null;
            this.categories.forEach(category => {
                if (category.value === type) {
                    cat = category.translated;
                }
            });

            return cat !== null ? cat : "search.category.house";
        },
        accommodationRooms(rooms) {
            const data = {};
            rooms.forEach(room => {
                Object.keys(room).forEach(field => {
                    if (data[field]) {
                        data[field] = data[field] + room[field];
                    } else {
                        if (room[field] > 0 && field !== "idpieza") {
                            data[field] = room[field];
                        }
                    }
                });
            });

            let text = "";
            let i = 0;
            Object.keys(data).forEach(key => {
                if (i < 6) {
                    const name = `search.filter_list.${key}`;
                    text +=
                        key > 1
                            ? `${data[key]} ${this.$t(name)} · `
                            : `${this.$t(name)} · `;
                }
                i++;
            });

            return text;
        },
        favorite() {
            if (this.$store.getters.isLoggedIn) {
                axios.post(`${process.env.VUE_APP_API_URL}wishList/change`, {
                    alojamiento_idalojamiento: this.accommodation.idalojamiento
                });
                this.hearth = !this.hearth;
            } else {
                if (this.$i18n.locale === "es") {
                    alert(
                        "Debes iniciar sesión para agregar este alojamiento a favoritos"
                    );
                } else {
                    alert(
                        "You must login to add this accommodation to favorites"
                    );
                }
            }
        }
    },
    computed: {
        ...mapState(["user"]),
        showMonthlyPrice() {
            return this.accommodation.modalidad_tiempo === 'mensual' || this.accommodation.modalidad_tiempo === 'ambos';
        },
        showDailyPrice() {
            return this.accommodation.modalidad_tiempo === 'diario' || this.accommodation.modalidad_tiempo === 'ambos';
        },
        title() {
            // The title is built with the categoría + tipo - alojamientoid
            return this.$t('accommodation.title.' + this.accommodation.tipo + '-' + this.accommodation.categoria)
                + " - ID: " + this.accommodation.idalojamiento;
        },
        location() {
            return this.$route.query.direccion_completa;
        }
    },
    created() {
        if (this.accommodation.wishlist) {
            this.hearth = true;
        }
    }
};
</script>

<style scoped lang="scss">

.accommodation-item {
    
    color: $grey-75;
    margin-top: 10px !important;
    
    .fav {
        
        position: relative;
        top: 30px;
        left: 20px;

        img {
            width: 27px;
            height: 27px;
            z-index: 99;
            background-color: #fff;
            border-radius: 50%;
            padding: 6px 3px;
            position: absolute;
        }
    }

    &__content {
        
        .picture {
            padding: 10px;
        }

        .info-data {

            font-size: 1rem;
            padding: 15px 10px 10px 10px;
            //font-weight: lighter;
            
            @media (max-width: $md) {
                padding-top: 0;
            }

            .location {
                font-weight: bolder;
                //height: 75px;
                overflow: hidden;
            }
            
            .equipments {
                //height: 58px;
                overflow: hidden;
            }

            .values {
                text-align: right;
                margin-top: 10px;
                
                .monthly {
                    font-size: 1rem;
                    font-weight: bold;
                }
                
                .daily {
                    font-size: 0.9rem;
                    
                }
                
                .dates-range {
                    font-size: 0.9rem;
                }
                
                .total {
                    font-size: 1rem;
                }
            }
        }
    }
}
</style>
<style lang="scss" scoped>
.circular-button {
    background-color: white;
    border-radius: 50%;
    border: solid 1px #c4c4c4;
    margin-left: 10px;
    width: 20px;
    height: 20px;
    outline: 0;
    margin-top: 0;
    font-size: 10px;
    background: #575757;

    svg {
        color: #FFF;
    }

}
</style>
	